import React from "react";
import ServiceHeroSection from "./service-hero";
import ServiceNavbar from "./service-navbar";
import ServiceWorks from "./service-works";
import ServiceStack from "./service-stack";
import ServicePricing from "./service-pricing";
import ServiceVision from "./service-vision";
import ServiceFooter from "./service-footer";

function WebsiteServiceLanding() {
  return (
    <div>
      <ServiceNavbar />
      <ServiceHeroSection />
      <ServiceWorks />
      <ServiceStack />
      <ServicePricing />
      <ServiceVision />
      <ServiceFooter />
    </div>
  );
}

export default WebsiteServiceLanding;
