import React from "react";
import HeroSection from "./hero-section";
import "./index.css";

const OurServices: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <ServicesSection />
      <WhyUsSection />
      <TechnologiesSection />
      <PricingSection />
      <DelnieCloudSection />

    </div>
  );
};

const Header: React.FC = () => {
  return (
    <header>
      <div className="container">
        <nav className="navbar">
          <div className="logo">
            <h1>Delnie Web Solutions</h1>
          </div>
          <ul className="nav-links">
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#why-us">Why Us?</a>
            </li>
            <li>
              <a href="#technologies">Technologies</a>
            </li>
            <li>
              <a href="#pricing">Pricing</a>
            </li>
            <li>
              <a href="#delnie-cloud">Delnie Cloud</a>
            </li>
            <li>
              <a href="#consultation">Free Consultation</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

  

const ServicesSection: React.FC = () => {
  return (
    <section id="services" className="services-section">
      <div className="container">
        <h2 className="section-title">Our Services</h2>
        <div className="content-container">
          <div className="content-box">
            <h3>Full-stack Web Development</h3>
            <p>
              We leverage Next.js and React for frontend development, ensuring a
              smooth and dynamic user experience, combined with Node.js for a
              powerful backend.
            </p>
          </div>
          <div className="content-box">
            <h3>API Solutions</h3>
            <p>
              We craft scalable and secure RESTful and GraphQL APIs for seamless
              integration with your applications.
            </p>
          </div>
          <div className="content-box">
            <h3>Database Management</h3>
            <p>
              We utilize MongoDB to create flexible and scalable databases for
              modern web applications.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhyUsSection: React.FC = () => {
  return (
    <section id="why-us" className="why-section">
      <div className="container">
        <h2 className="section-title">Why Choose Us?</h2>
        <div className="content-container">
          <div className="content-box">
            <h3>Performance and Scalability</h3>
            <p>
              We use cutting-edge technologies like Next.js, Node.js, and
              MongoDB to build highly scalable and high-performance web
              applications.
            </p>
          </div>
          <div className="content-box">
            <h3>Full-Stack JavaScript</h3>
            <p>
              With a unified JavaScript stack, we offer end-to-end development
              using the same language across the front and back end, ensuring
              faster and more efficient development.
            </p>
          </div>
          <div className="content-box">
            <h3>Seamless User Experience</h3>
            <p>
              We create fast, dynamic web applications that provide a seamless
              user experience by leveraging Next.js for server-side rendering
              and dynamic routing.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const TechnologiesSection: React.FC = () => {
  return (
    <section id="technologies" className="technologies-section">
      <div className="container">
        <h2 className="section-title">Why Next.js, Node.js, and MongoDB?</h2>
        <div className="content-container">
          <div className="content-box">
            <h3>Why Next.js?</h3>
            <p>
              Next.js is SEO-friendly, offers server-side rendering, static
              generation, and provides great performance with minimal effort.
              It’s perfect for building fast, scalable web apps.
            </p>
          </div>
          <div className="content-box">
            <h3>Why Node.js?</h3>
            <p>
              Node.js is ideal for handling real-time, data-intensive
              applications that require high throughput, non-blocking I/O, and
              scalability.
            </p>
          </div>
          <div className="content-box">
            <h3>Why MongoDB?</h3>
            <p>
              MongoDB’s flexible schema, scalability, high performance, and
              seamless integration with Node.js make it the best choice for
              dynamic, large-scale data applications.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const PricingSection: React.FC = () => {
  return (
    <section id="pricing" className="pricing-section">
      <div className="container">
        <h2 className="section-title">Our Pricing Plans</h2>
        <div className="pricing-table">
          <div className="pricing-card">
            <h3>Basic Plan</h3>
            <p className="price">₹20,000</p>
            <ul>
              <li>Backend Development (Next.js, Node.js)</li>
              <li>Basic API Development</li>
              <li>MongoDB Integration</li>
              <li>Email Support</li>
            </ul>
            <a href="#consultation" className="cta-btn">
              Choose Basic
            </a>
          </div>
          <div className="pricing-card">
            <h3>Advanced Plan</h3>
            <p className="price">₹60,000</p>
            <ul>
              <li>All Basic Plan Features</li>
              <li>Advanced API Development</li>
              <li>Frontend with React</li>
              <li>3 Months Maintenance</li>
              <li>Priority Support</li>
            </ul>
            <a href="#consultation" className="cta-btn">
              Choose Advanced
            </a>
          </div>
          <div className="pricing-card">
            <h3>Premium Plan</h3>
            <p className="price">₹1,50,000</p>
            <ul>
              <li>All Advanced Plan Features</li>
              <li>Full-stack Development</li>
              <li>Custom API & Database Solutions</li>
              <li>6 Months Maintenance</li>
              <li>Dedicated Support</li>
            </ul>
            <a href="#consultation" className="cta-btn">
              Choose Premium
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const DelnieCloudSection: React.FC = () => {
  return (
    <section id="delnie-cloud" className="delnie-section">
      <div className="container">
        <h2 className="section-title">Delnie Cloud Admin</h2>
        <p>
          Manage your data with ease using Delnie Cloud Admin, now free for one
          year for all data operations!
        </p>
        <div className="content-container">
          <div className="content-box">
            <h3>Features</h3>
            <ul>
              <li>Real-time monitoring and management of datasets</li>
              <li>Automated backups and recovery systems</li>
              <li>Direct integration with MongoDB and MongoDB Charts</li>
              <li>User-friendly interface for non-technical users</li>
              <li>Secure, role-based access control</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};


export default OurServices;
