import * as React from "react";

import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

export default function ServiceHeroSection() {
  return (
    <div className="flex justify-between items-center pt-[114px] pr-[116px] pl-20">
      <div className="w-1/2">
        <h2 className="font-raleway font-bold text-[43.99px] leading-[52.8px] mb-1.5">
          Turn ideas into reality{" "}
        </h2>
        <h3 className="font-urbanist font-black text-2xl leading-[52.8px] mb-1.5">
          With
        </h3>
        <h2 className="font-raleway font-bold text-[43.99px] leading-[52.8px] mb-3">
          Advance website solutions
        </h2>
        <h4 className="font-lato font-normal text-xl leading-7">
          Get a custom website designed with user experience at the forefront.
          We'll handle everything from branding to development, ensuring your
          online presence stands out.
        </h4>
      </div>
      <Card className="w-[350px] rounded-[20px] max-w-[19rem]">
        <CardHeader>
          <CardTitle>Get Free Consultation</CardTitle>
          {/* <CardDescription>
            Deploy your new project in one-click.
          </CardDescription> */}
        </CardHeader>
        <CardContent>
          <form className="mb-3">
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                {/* <Label htmlFor="name">Name</Label> */}
                <Input
                  id="name"
                  placeholder="Name"
                  className="bg-black/[0.1] rounded-none placeholder:text-black"
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                {/* <Label htmlFor="phone">Phone</Label> */}
                <Input
                  id="name"
                  placeholder="Phone"
                  className="bg-black/[0.1] rounded-none placeholder:text-black"
                />
              </div>
            </div>
          </form>
          <h5 className="w-2/3 font-lato font-normal text-[12px] leading-110">
            Your information is safe with us. We respect your privacy.
          </h5>
        </CardContent>
        <CardFooter>
          <Button className="w-full rounded-[8px] bg-black">
            Request free consultation
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
