import React from "react";
import "./index.css";

const HeroSection: React.FC = () => {
  return (
    <section className="hero-section-ad">
      <div className="hero-content-ad">
        <h2>Get a Free Estimation Today!</h2>
        <p>
          Share your phone number with us, and our expert developers will provide you with a free consultation and cost estimate for your project.
        </p>
      </div>

      {/* Consultation Form Starts Here */}
      <div className="hero-form-ad">
        <form className="consultation-form-ad" action="/submit-form" method="POST">
          <div className="form-group-ad">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="form-group-ad">
            <label htmlFor="phone">Your Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              required
            />
          </div>
          <button type="submit" className="cta-btn-ad secondary-btn-ad">
            Request Free Estimation
          </button>
        </form>
      </div>
      {/* Consultation Form Ends Here */}
    </section>
  );
};

export default HeroSection;
