import {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarRadioGroup,
  MenubarRadioItem,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "../../../components/ui/menubar";
import DelnieLogo from "../../../../assets/images/logo/delnie-logo.svg";

const menubarItems = [
  {
    id: 0,
    title: "Home",
  },
  {
    id: 1,
    title: "About Us",
  },
  {
    id: 2,
    title: "Our Service",
  },
  {
    id: 3,
    title: "Our Product",
  },
  {
    id: 4,
    title: "The Squad",
  },
  {
    id: 5,
    title: "Contact Us",
  },
];

export default function ServiceNavbar() {
  return (
    <div className="w-full flex justify-between items-center pl-20 pr-[110px] pt-5">
      <img src={DelnieLogo} alt="" />
      <Menubar className="!border-none bg-transparent">
        {menubarItems.map((item: any) => (
          <MenubarMenu key={item.id}>
            <MenubarTrigger className="focus:bg-transparent data-[state=open]:bg-transparent hover:bg-transparent relative group">
              {item.title}
              <span className="absolute left-0 bottom-0 w-full h-0.5 scale-x-0 bg-[#FF015A] transition-transform duration-200 group-hover:scale-x-50" />
            </MenubarTrigger>
            {/* <MenubarContent>
              <MenubarItem>
                New Tab <MenubarShortcut>⌘T</MenubarShortcut>
              </MenubarItem>
              <MenubarItem>
                New Window <MenubarShortcut>⌘N</MenubarShortcut>
              </MenubarItem>
              <MenubarItem disabled>New Incognito Window</MenubarItem>
              <MenubarSeparator />
              <MenubarSub>
                <MenubarSubTrigger>Share</MenubarSubTrigger>
                <MenubarSubContent>
                  <MenubarItem>Email link</MenubarItem>
                  <MenubarItem>Messages</MenubarItem>
                  <MenubarItem>Notes</MenubarItem>
                </MenubarSubContent>
              </MenubarSub>
              <MenubarSeparator />
              <MenubarItem>
                Print... <MenubarShortcut>⌘P</MenubarShortcut>
              </MenubarItem>
            </MenubarContent> */}
          </MenubarMenu>
        ))}
      </Menubar>
    </div>
  );
}
